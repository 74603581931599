<script setup>
import { computed, getCurrentInstance } from 'vue'
import InputError from '@/Components/InputError.vue'

const emit = defineEmits(['update:checked'])

const props = defineProps({
    checked: {
        type: [Array, Boolean],
        required: true,
    },
    value: {
        default: null,
    },
    error: {
        type: String,
        default: null,
    },
    label: {
        required: false,
        type: String,
        default: null,
    },
})

const proxyChecked = computed({
    get() {
        return props.checked;
    },

    set(val) {
        emit('update:checked', val);
    },
})

const uid = computed(() => getCurrentInstance()?.uid)
</script>

<template>
    <input v-bind="$attrs" :value="value" v-model="proxyChecked" :id="uid" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
    <label :for="uid" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
        <template v-if="label">{{ label }}</template>
        <slot v-else/>
    </label>

    <InputError v-if="error" class="mt-2" :message="error" />
</template>
