<script setup>
import InputError from '@/Components/InputError.vue'
import { Head, Link, router, useForm, usePage } from '@inertiajs/vue3'
import GuestLayout from '@/Layouts/GuestLayout.vue'
import VueTurnstile from 'vue-turnstile'
import { ref } from 'vue'
import TextInput from '@/Components/TextInput.vue'
import PrimaryButton from '@/Components/PrimaryButton.vue'
import Checkbox from '@/Components/Checkbox.vue'
import SecondaryButton from '@/Components/SecondaryButton.vue'
import { trans } from 'laravel-vue-i18n'
import { motion } from 'motion-v'

const page = usePage()

const step = ref('check')
const checkCaptcha = ref()
const loginCaptcha = ref()
const registerCaptcha = ref()

defineProps({
    users: {
        type: Array,
    },
    isLocal: {
        type: Boolean,
    },
    isCaptchaEnabled: {
        type: Boolean,
    },
    captchaSiteKey: {
        type: String,
    },
})

const form = useForm({
    email: '',
    password: '',
    captcha: '',
})

const registerForm = useForm({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    terms: false,
    captcha: '',
})

const userCheck = () => {
    form.post(route('login.check'), {
        preserveScroll: true,
        onSuccess: () => {
            step.value = page?.props?.flash?.swal?.check ? 'login' : 'register'
        },
        onFinish: () => checkCaptcha.value?.reset()
    })
}

const userLogin = () => {
    form.post(route('login'), {
        preserveScroll: true,
        onSuccess: () => {
            form.reset()
        },
        onFinish: () => {
            form.password = ''
            loginCaptcha.value?.reset()
        }
    })
}

const userRegister = () => {
    registerForm
        .transform((data) => ({
            ...data,
            email: form.email,
        }))
        .post(route('register'), {
            preserveScroll: true,
            onSuccess: () => {
                form.reset()
            },
            onFinish: () => registerCaptcha.value?.reset()
        })
}

</script>

<template>
    <img src="@/../images/back-icon.svg" alt="Back" class="back-icon absolute p-4 cursor-pointer" v-if="step !== 'check'" @click="router.visit(route('auth'))">
    <GuestLayout>
        <Head title="Log in" />

        <motion.div
            :key="step"
            :initial="{ opacity: 0, x: -20 }"
            :animate="{ opacity: 1, x: 0 }"
            :exit="{ opacity: 0, x: 20 }"
            :transition="{ duration: 0.3 }"
            class="text-left max-w-md w-full"
        >
            <template v-if="step === 'register'">
                <h1 class="text-black text-center text-xl font-normal leading-7.5 mb-2">Bine ai venit!</h1>

                <p class="text-black text-center text-sm font-normal leading-5 mb-8">
                    {{ trans('It looks like you don\'t have an account with MemoRama') }} <br />
                    {{ trans('Complete the fields below and start sharing memories !') }}
                </p>
            </template>

            <h1 class="text-neutral-900 text-xl font-medium leading-7.5 mb-4">{{ trans('My account') }}</h1>

            <template v-if="step === 'check'">
                <form @submit.prevent="userCheck">
                    <TextInput v-model="form.email" :error="form.errors.email" :label="trans('E-mail address')" :placeholder="trans('name@example.com')" autofocus required/>

                    <InputError class="mt-2" :message="form.errors.captcha" />

                    <p class="text-black text-sm font-normal leading-5 mt-4 mb-4">
                        {{ trans('No account? Don\'t worry, we will create one for you in the next step') }}
                    </p>

                    <vue-turnstile v-if="isCaptchaEnabled" ref="checkCaptcha" :site-key="captchaSiteKey" v-model="form.captcha" action="check" />

                    <PrimaryButton :processing="form.processing" type="submit">
                        {{ trans('Continue') }}
                    </PrimaryButton>
                </form>

                <div class="flex items-center mb-4">
                    <div class="flex-grow h-[1px] bg-gray-500"></div>
                    <span class="text-gray-500 text-sm font-medium px-2.5 leading-5">{{ trans('or') }}</span>
                    <div class="flex-grow h-[1px] bg-gray-500"></div>
                </div>

                <div class="grid grid-cols-1 gap-2">
                    <a :href="route('login.redirect', { provider: 'google' })" class="w-full border border-gray-500 rounded-lg p-3 hover:bg-primary-100">
                        <div class="relative flex justify-center">
                            <img src="@/../images/google.svg" alt="Google" class="absolute left-0" />
                            <span class="flex justify-center self-center items-center">{{ trans('Continue with e-mail') }}</span>
                        </div>
                    </a>
                    <a :href="route('login.redirect', { provider: 'apple' })" class="w-full border border-gray-500 rounded-lg p-3 hover:bg-primary-100">
                        <div class="relative flex justify-center">
                            <img src="@/../images/apple.svg" alt="Apple" class="absolute left-0" />
                            <span class="flex justify-center self-center items-center">{{ trans('Continue with apple') }}</span>
                        </div>
                    </a>
                </div>
            </template>

            <template v-if="step === 'login'">
                <p class="text-gray-500 text-sm font-normal leading-5 mb-4">{{ form.email }}</p>

                <form @submit.prevent="userLogin">
                    <TextInput v-model="form.password" :error="form.errors.email" :label="trans('Password')" type="password" placeholder="**********" required autofocus/>

                    <InputError class="mt-2" :message="form.errors.password" />
                    <InputError class="mt-2" :message="form.errors.captcha" />

                    <Link class="text-primary-700 text-sm font-normal leading-5 mt-4 mb-4 cursor-pointer underline-none inline-block" :href="route('password.request')">{{ trans('Forgot password') }}</Link>

                    <vue-turnstile v-if="isCaptchaEnabled" ref="loginCaptcha" :site-key="captchaSiteKey" v-model="form.captcha" action="login" />

                    <PrimaryButton :processing="form.processing" type="submit">
                        {{ trans('Continue') }}
                    </PrimaryButton>
                </form>
            </template>

            <template v-if="step === 'register'">
                <p class="text-gray-500 text-sm font-normal leading-5 mb-4">{{ form.email }}</p>

                <form @submit.prevent="userRegister">
                    <TextInput v-model="registerForm.name" :error="registerForm.errors.name" :label="trans('First and last name')" :placeholder="trans('Your full name')" required/>
                    <TextInput v-model="registerForm.password" :error="registerForm.errors.password" :label="trans('Password')" placeholder="**********" type="password" required/>
                    <TextInput v-model="registerForm.password_confirmation" :error="registerForm.errors.password_confirmation" :label="trans('Confirm password')" placeholder="**********" type="password" required/>

                    <div class="mt-2 mb-2">
                        <Checkbox v-model="registerForm.terms" :error="registerForm.errors.terms" :checked="registerForm.terms">
                            {{ trans('I accept') }} <a :href="route('legal.terms-conditions')" target="_blank" class="text-primary-700">{{ trans('Terms and Conditions') }}</a> {{ trans('and') }} <a :href="route('legal.privacy-policy')" target="_blank" class="text-primary-700">{{ trans('Privacy Policy') }}</a>
                        </Checkbox>
                    </div>

                    <vue-turnstile v-if="isCaptchaEnabled" ref="registerCaptcha" :site-key="captchaSiteKey" v-model="registerForm.captcha" action="register" />

                    <PrimaryButton :processing="registerForm.processing" type="submit">
                        {{ trans('Continue') }}
                    </PrimaryButton>
                </form>
            </template>
        </motion.div>

        <template v-if="isLocal">
            <SecondaryButton
                v-for="user in users"
                @click="router.post(route('login'), { email: user.email, password: 'password' })"
                type="button"
                class="mt-4 mr-1 mb-1 border focus:ring-4 focus:outline-none font-medium rounded-lg px-3 py-2 text-xs text-center me-2 border-zinc-600 text-gray-400 hover:text-white hover:bg-zinc-600 focus:ring-gray-800"
            >
                {{ user.email }}
            </SecondaryButton>
        </template>
    </GuestLayout>
</template>
